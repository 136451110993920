export default function useUpdateShopifyFavorites() {
	const updateShopifyFavorite = async (productId: string, operation: 'add' | 'remove') => {
		if (operation === 'remove') {
			// Remove favorite from Shopify
			fetch('/api/shopify/update-favorites', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					id: productId,
					action: 'remove'
				})
			})
		} else if (operation === 'add') {
			// Remove favorite from Shopify
			fetch('/api/shopify/update-favorites', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					id: productId,
					dateAdded: Date.now(),
					action: 'add'
				})
			})
		}
	}

	return updateShopifyFavorite
}
