import type { Product } from '@lib/types'

export default function StarRatings({ avgRating }: { avgRating: Product['avgRating'] }) {
	const halfOrWhole = (min: number, max: number) => {
		if (typeof avgRating !== 'number') return null

		// Star with no fill
		if (avgRating <= min) {
			return 'fa-regular fa-star text-bbx-spark'
		}
		// Half star
		else if (avgRating > min && avgRating <= max) {
			return 'fa-solid fa-star-half-stroke text-bbx-spark'
		}
		// Full star
		return 'fa-solid fa-star text-bbx-spark'
	}

	return avgRating && avgRating >= 1 ? (
		<div className="flex text-xs space-x-0.5">
			{/* 1 */}
			<i className="fa-solid fa-star text-bbx-spark" />
			{/* 2 */}
			<i className={`${halfOrWhole(1, 1.5)}`} />
			{/* 3 */}
			<i className={`${halfOrWhole(2, 2.5)}`} />
			{/* 4 */}
			<i className={`${halfOrWhole(3, 3.5)}`} />
			{/* 5 */}
			<i className={`${halfOrWhole(4, 4.9)}`} />
		</div>
	) : null
}
